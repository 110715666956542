.modalMask {
    // display: none;
    position: fixed;
    z-index: 1;
    padding-top: 200px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  
    .modalContainer {
      background-color: #fefefe;
      margin: auto;
      max-width: 540px;
      border-radius: 10px;
  
      .modalHeader {
        background-color: #0e9e92;
        text-align: center;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
  
        h1 {
          color: #fff;
          font-size: 18px;
          margin: 15px;
          padding: 25px 15px;
          text-transform: none;
        }
      }
  
      .modalBody {
        padding: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .btnContainer {
          margin-right: 30px;
          padding: 0;
  
          .globalBtn {
            outline: none;
            border: none;
            background-color: #00897b;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            padding: 1rem 4rem;
            transition: 0.2s ease;
            border-radius: 0;
            min-width: 120px;
  
            &:hover,
            &:focus,
            &:active {
              background-color: #0a412b;
              color: #fff;
              outline: 0;
            }
          }
  
          .globalGreyBtn {
            outline: none;
            border: none;
            background-color: #353535;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            padding: 1rem 4rem;
            transition: 0.2s ease;
            border-radius: 0;
            cursor: pointer;
            min-width: 120px;
  
            &:hover,
            &:focus,
            &:active {
              background-color: #858585;
              color: #ffffff;
              outline: 0;
            }
          }
  
          .globalWhiteBtn {
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
            padding: 12px;
            font-size: 13px;
            font-weight: 600;
            border: none;
            color: #353535;
            cursor: pointer;
            outline: 0;
            width: 100%;
  
            &:hover {
              background-color: #ffde00;
              color: #ffffff;
              outline: 0;
            }
  
            &:active {
              background-color: #28285b;
              color: #ffffff;
              outline: 0;
            }
          }
        }
      }
    }
  }