@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  list-style: none;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background: #f5f6fa;
}

@media only screen and (max-width: 800px) {
  .main .sidebar{
    display: none;
  }
}

.main .sidebar {
  background: #00897b;
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  // min-height: 100vh;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
  overflow: auto;
}

.main .sidebar .profile {
  margin-bottom: 30px;
  text-align: center;
}

.main .sidebar .profile img {
  display: block;
  width: 100px;
  margin: 0 auto;
}

.main .sidebar .profile h3 {
  color: #ffffff;
  margin: 10px 0 5px;
}

.main .sidebar .profile p {
  color: rgb(206, 240, 253);
  font-size: 14px;
}

.main .sidebar ul {
  padding: 0;
}

.main .sidebar ul li a {
  display: block;
  padding: 13px 30px;
  border-bottom: 1px solid #127844;
  color: rgb(241, 237, 237);
  font-size: 16px;
  position: relative;
}

.main .sidebar ul li a .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}

.main .sidebar ul li a:hover,
.main .sidebar ul li a.active {
  color: #0c7db1;

  background: white;
  border-right: 2px solid rgb(5, 68, 104);
}

.main .sidebar ul li a:hover .icon,
.main .sidebar ul li a.active .icon {
  color: #0c7db1;
}

.main .sidebar ul li a:hover:before,
.main .sidebar ul li a.active:before {
  display: block;
}

.main .section {
  width: calc(100% - 225px);
  margin-left: 225px;
  transition: all 0.5s ease;
  background-color: #f5f6fa;
}

@media only screen and (max-width: 800px) {
  .main .section{
    width: 100%;
    margin-left: 0;
    transition: all 0.5s ease;
  }
  .top_navbar {
    display: none !important;
  }
}

@media only screen and (min-width: 801px){
  .main .mobileNavbar {
    display: none;
  }
}

.main .mobileNavbar {
  background: #0e9e92 !important;

  img {
    max-width: 100px;
  }
}

.main .section .top_navbar {
  background: #0e9e92;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.main .section .top_navbar .hamburger a {
  font-size: 28px;
  color: #f4fbff;
}

.main .section .top_navbar .hamburger a:hover {
  color: #a2ecff;
}

.main .section .container {
  margin: 30px;
  background: #fff;
  padding: 50px;
  line-height: 28px;
  max-width: 1200px;
}

body.active .main .sidebar {
  left: -225px;
}

body.active .main .section {
  margin-left: 0;
  width: 100%;
}

.editProfile a{
  color: rgb(69, 69, 69) !important;
}
