.containerLabel {
    // float:left;
    margin: 5px;
}

.containerInput {
    float: left;
    margin: 5px;
    // width: 200px;
}

.resource {
    margin-top: 30px;
    margin-bottom: 10px;
}

.accessControl {
    margin-top: 50px !important;
}

.buttons {
    margin-top: 10px !important; 

    button {
        margin-right: 5px;
    }
}