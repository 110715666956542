
  // table {
  //   border: 1px solid #ddd;
  //   text-align: left;
  //   border-collapse: collapse;
  //   width: 100%;
  // }
  
  // th{
  //   padding: 15px;
  //   border: 1px solid #ddd;
  //   text-align: left;
  // }

  // td {
  //   padding: 15px;
  //   border: 1px solid #ddd;
  //   text-align: left;
  // }

  .container {
    max-width: 1000px;
    // margin-left: auto;
    // margin-right: auto;
    // padding-left: 10px;
    // padding-right: 10px;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .responsiveTable {
    margin: 0;
    padding: 0;
    li {
      border-radius: 3px;
      padding: 15px 30px;
      display: flex;
      justify-content: space-between;
      // margin-bottom: 25px;
    }
    .tableHeader {
      // background-color: #95A5A6;
      background-color: #bdcccc;
      color: rgb(39, 38, 38);
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .tableRow {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col {
      a {
        margin-right: 3px;
      }
    }
    .col1 {
      flex-basis: 15%;
    }
    .col2 {
      flex-basis: 35%;
    }
    .col3 {
      flex-basis: 35%;
    }
    .col4 {
      flex-basis: 15%;
    }
    
    @media all and (max-width: 767px) {
      .tableHeader {
        display: none;
      }
      .tableRow{
        
      }
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }