
// .containerLogin {
//     display: flex;          
//     flex-direction: row;  
//     justify-content: center; 
//     align-items: center;     
//     min-height: 600px;
//     // height: 300px;
// }


.containerLogin {
    // margin: 0;
    width: 100%;
    display: flex;          
    flex-direction: row;  
    justify-content: center; 
    align-items: center;     
    height: 100%;
    min-height: 800px;
    // height: 300px;
}

.login-container {
    height: 100vh;
  }
  
  .left-pane {
    background-color: #f8f9fa; /* Cor de fundo da esquerda */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-pane {
    background-color: #ffffff; /* Cor de fundo da direita */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto;
    max-height: 150px; /* Ajuste a altura máxima conforme necessário */
  }
  
  .login {
    width: 100%;
    background: url('../../../src/shared/img/ong-bg.jpg')
  }