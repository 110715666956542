.headerInfo {
    display: flex;
    justify-content: space-between;

    margin: 20px 0 40px;

    a {
        line-height: 2;
        background-color: #00897b;
        border: #00897b;
    }
}

.hr {
    color: gray;
}

.buttonDefault {
    background-color: #00897b;
    border: #00897b;
}

// .textError {
//     margin-top: 0.25rem;
//     font-size: 11px !important;
//     color: red !important;
// }

.valueInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}